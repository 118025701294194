/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-button {
  background-color: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px;
}
.viewer-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.viewer-button-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-menu-divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  margin: 4px 0px;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-menu-item {
  align-items: center;
  display: flex;
  padding: 4px 0px;
}
.viewer-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.viewer-menu-item-icon {
  padding-left: 16px;
  padding-right: 8px;
}
.viewer-menu-item-label {
  flex-grow: 1;
  flex-shrink: 1;
  padding-right: 32px;
  white-space: nowrap;
}
.viewer-menu-item-check {
  padding-right: 16px;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-progress-bar {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 9999px;
}
.viewer-progress-bar-inner {
  align-items: center;
  background-color: #357edd;
  border-radius: 9999px;
  color: #ffffff;
  display: flex;
  font-size: 10px;
  justify-content: center;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-separator {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-toolbar {
  align-items: center;
  display: flex;
  width: 100%;
}
.viewer-toolbar-left {
  align-items: center;
  display: flex;
}
.viewer-toolbar-center {
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: center;
}
.viewer-toolbar-right {
  align-items: center;
  display: flex;
  margin-left: auto;
}
.viewer-toolbar-item {
  padding: 0px 2px;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-primary-button {
  background-color: #357edd;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  padding: 8px;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-icon {
  fill: none;
  stroke: #000000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1;
  text-align: center;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-popover-body {
  background: #FFF;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  left: 0;
  padding: 8px 0;
  position: absolute;
  top: -9999px;
  z-index: 9999;
}
.viewer-popover-body-arrow {
  background: #ffffff;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-layout-container {
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: 44px calc(100% - 44px);
  grid-template-rows: 44px calc(100% - 44px);
  height: 100%;
  position: relative;
  width: 100%;
}
.viewer-layout-toolbar {
  align-items: center;
  background-color: #eeeeee;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 2;
  grid-column-end: 3;
  -ms-grid-row: 1;
  grid-row: 1;
  justify-content: center;
  padding: 4px;
}
.viewer-layout-sidebar {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  display: none;
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 2;
  grid-row: 2;
  justify-content: center;
}
.viewer-layout-main {
  -ms-grid-column: 1;
  grid-column: 1;
  -ms-grid-row: 2;
  grid-row: 2;
  overflow: scroll;
}
/* With sidebar opened */
.viewer-layout-with-sidebar.viewer-layout-container {
  -ms-grid-columns: 30% 1fr;
  grid-template-columns: 30% 1fr;
}
.viewer-layout-with-sidebar .viewer-layout-sidebar {
  display: flex;
}
.viewer-layout-with-sidebar .viewer-layout-main {
  -ms-grid-column: 2;
  grid-column: 2;
  -ms-grid-row: 2;
  grid-row: 2;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0px;
  left: 0px;
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 9999;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-popover-overlay {
  bottom: 0px;
  left: 0px;
  position: fixed;
  right: 0px;
  top: 0px;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-tooltip-body {
  background: #000;
  border-radius: 4px;
  color: #FFF;
  left: 0;
  max-width: 300px;
  position: absolute;
  text-align: center;
  top: -9999px;
  z-index: 9999;
}
.viewer-tooltip-body-arrow {
  background-color: #000;
}
.viewer-tooltip-body-content {
  padding: 8px;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-modal-body {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  margin: 160px auto 0px;
  max-width: 480px;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-inner-pages-vertical {
  display: flex;
  flex-direction: column;
}
.viewer-inner-pages-horizontal {
  display: flex;
  flex-direction: row;
}
.viewer-inner-pages-wrapped {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.viewer-inner-page {
  padding: 8px;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-spinner {
  animation-duration: 750ms;
  animation-name: viewer-spinner-transform;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transition-property: transform;
}
.viewer-spinner-circle {
  fill: none;
  stroke: rgba(0, 0, 0, 0.4);
  stroke-linecap: round;
  stroke-width: 2;
}
@keyframes viewer-spinner-transform {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-arrow {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  border-left-color: rgba(0, 0, 0, 0.3);
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  border-top-color: rgba(0, 0, 0, 0.3);
  height: 10px;
  position: absolute;
  width: 10px;
  z-index: 0;
}
.viewer-arrow-tl {
  bottom: 0;
  left: 0;
  transform: translate(50%, 50%) rotate(45deg);
}
.viewer-arrow-tc {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
}
.viewer-arrow-tr {
  bottom: 0;
  right: 0;
  transform: translate(-50%, 50%) rotate(45deg);
}
.viewer-arrow-rt {
  left: 0;
  top: 0;
  transform: translate(-50%, 50%) rotate(135deg);
}
.viewer-arrow-rc {
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%) rotate(135deg);
}
.viewer-arrow-rb {
  bottom: 0;
  left: 0;
  transform: translate(-50%, -50%) rotate(135deg);
}
.viewer-arrow-bl {
  left: 0;
  top: 0;
  transform: translate(50%, -50%) rotate(225deg);
}
.viewer-arrow-bc {
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%) rotate(225deg);
}
.viewer-arrow-br {
  right: 0;
  top: 0;
  transform: translate(-50%, -50%) rotate(225deg);
}
.viewer-arrow-lt {
  right: 0;
  top: 0;
  transform: translate(50%, 50%) rotate(315deg);
}
.viewer-arrow-lc {
  right: 0;
  top: 50%;
  transform: translate(50%, -50%) rotate(315deg);
}
.viewer-arrow-lb {
  bottom: 0;
  right: 0;
  transform: translate(50%, -50%) rotate(315deg);
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-page-layer {
  align-items: center;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  margin: 0px auto;
  position: relative;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-page-size-calculator {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-print-zone {
  display: none;
}
@media print {
  .viewer-body-printing #root {
    display: none;
  }
  .viewer-print-zone {
    display: block;
  }
  .viewer-print-zone-page-thumbnail {
    overflow: hidden;
    page-break-after: auto;
    page-break-inside: avoid;
  }
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.viewer-sidebar-tabs {
  align-items: center;
  background-color: #eeeeee;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  padding: 4px;
}
.viewer-sidebar-tab {
  padding: 0px 2px;
}
.viewer-sidebar-content {
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
  padding: 8px 0px;
}
.viewer-sidebar-thumbnails {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-doc-error {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
}
.viewer-doc-error-text {
  background-color: #e53e3e;
  border-radius: 0.25rem;
  color: #fff;
  line-height: 1.5;
  max-width: 50%;
  padding: 0.5rem;
}
.viewer-doc-loading {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-drop-area {
  align-items: center;
  background-color: #ffffff;
  border: 2px dashed rgba(0, 0, 0, 0.3);
  bottom: 0px;
  display: flex;
  font-size: 24px;
  justify-content: center;
  left: 0px;
  position: absolute;
  right: 0px;
  top: 44px;
  z-index: 9999;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-toolbar-current-page-input {
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 4px;
  width: 50px;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-exit-fullscreen {
  bottom: 0;
  padding: 8px;
  position: fixed;
  right: 0;
}
.viewer-exit-fullscreen-inner {
  background-color: #FFF;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-text-layer {
  height: 100%;
  left: 0px;
  line-height: 1;
  position: absolute;
  top: 0px;
  width: 100%;
}
.viewer-text {
  color: transparent;
  cursor: text;
  opacity: 0.2;
  position: absolute;
  transform-origin: 0% 0%;
  white-space: pre;
}
.viewer-text::selection {
  background: #0000ff;
  color: transparent;
}
.viewer-text-highlight {
  background-color: #b400aa;
  border-radius: 4px;
  margin: -1px;
  padding: 1px;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-asking-password {
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}
.viewer-asking-password-message {
  margin: 8px 0px;
}
.viewer-asking-password-input-container {
  align-items: center;
  display: flex;
  justify-content: center;
}
.viewer-asking-password-input {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 8px;
  width: 200px;
}
.viewer-asking-password-button {
  background-color: #357edd;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left-color: transparent;
  color: #ffffff;
  cursor: pointer;
  padding: 8px 16px;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-thumbnail {
  padding: 8px;
}
.viewer-thumbnail:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
.viewer-thumbnail-selected {
  background-color: rgba(0, 0, 0, 0.3);
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-properties-modal {
  padding: 8px 0px;
}
.viewer-properties-modal-group {
  padding: 0px 8px;
}
.viewer-properties-modal-footer {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-grab {
  cursor: grab;
}
.viewer-grab :not(input),
.viewer-grab :not(select),
.viewer-grab :not(textarea) {
  cursor: grab !important;
}
.viewer-grabbing {
  cursor: grabbing;
}
.viewer-grabbing :not(input),
.viewer-grabbing :not(select),
.viewer-grabbing :not(textarea) {
  cursor: grabbing !important;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-zoom-popover-target {
  align-items: center;
  display: flex;
  padding: 8px;
}
.viewer-zoom-popover-target-scale {
  margin-right: 4px;
}
.viewer-zoom-popover-target-arrow {
  border-color: rgba(0, 0, 0, 0.6) transparent transparent;
  border-style: solid;
  border-width: 8px 4px 0px;
  height: 0px;
  width: 0px;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-annotation-link a {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.viewer-annotation-link a:hover {
  background: rgba(255, 255, 0, 0.2);
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-bookmark-empty {
  text-align: center;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-canvas-layer {
  left: 0px;
  position: absolute;
  overflow: hidden;
  top: 0px;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-print-progress {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 9999;
}
.viewer-print-progress-inner {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 24px;
  text-align: center;
  width: 240px;
}
.viewer-print-progress-bar {
  margin-bottom: 16px;
}
.viewer-print-progress-message {
  margin-bottom: 8px;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-attachment-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
.viewer-attachment-list-empty {
  text-align: center;
}
.viewer-attachment-item {
  padding: 8px;
}
.viewer-attachment-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-open-file {
  background-color: transparent;
  border: none;
  border-radius: 4px;
  padding: 8px;
  position: relative;
}
.viewer-open-file:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.viewer-open-file-input {
  bottom: 0px;
  cursor: pointer;
  height: 100%;
  left: 0px;
  opacity: 0;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 100%;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-thumbnail-container {
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 8px 0px;
  display: flex;
  justify-content: center;
  margin: 0px auto;
  position: relative;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-bookmark-list {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-search-popover {
  padding: 0 8px;
}
.viewer-search-popover-input-counter {
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  margin-bottom: 8px;
  position: relative;
  width: 180px;
}
.viewer-search-popover-input {
  border: none;
  padding: 4px;
  width: 100%;
}
.viewer-search-popover-counter {
  align-items: center;
  bottom: 0px;
  display: flex;
  padding-right: 4px;
  position: absolute;
  right: 0px;
  top: 0px;
}
.viewer-search-popover-label {
  align-items: center;
  display: flex;
  margin-bottom: 8px;
}
.viewer-search-popover-label-checkbox {
  margin-right: 4px;
}
.viewer-search-popover-footer {
  align-items: center;
  display: flex;
}
.viewer-search-popover-footer-item {
  padding: 0px 4px;
}
.viewer-search-popover-footer-button {
  margin-left: auto;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-properties-loader {
  text-align: center;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-property-item {
  margin: 8px 0px;
}
.viewer-property-item-label {
  display: inline-block;
  padding-right: 8px;
  width: 30%;
}
.viewer-property-item-value {
  display: inline-block;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-menu {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-annotation {
  position: absolute;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-bookmark-item {
  align-items: center;
  cursor: pointer;
  display: flex;
  padding-bottom: 6px;
  padding-right: 4px;
  padding-top: 6px;
}
.viewer-bookmark-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.viewer-bookmark-toggle {
  margin-right: 4px;
  transform: rotate(90deg);
}
.viewer-bookmark-toggle-expanded {
  transform: rotate(0deg);
}
.viewer-bookmark-title {
  flex-grow: 1;
  flex-shrink: 1;
  text-decoration: none;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.viewer-annotation-popup-wrapper {
  background-color: #faf089;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  font-size: 0.75rem;
  margin-left: 0.25rem;
  position: absolute;
  top: 0;
  width: 16rem;
  word-wrap: break-word;
}
.viewer-annotation-popup-wrapper-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0.25rem;
}
.viewer-annotation-popup-wrapper-title {
  font-weight: 600;
  margin-right: 1rem;
}
.viewer-annotation-popup-wrapper-content {
  border-top: 1px solid #1a202c;
  padding: 0.25rem;
}
